import ContactsIcon from '@material-ui/icons/Contacts';

import { UserShow } from './UserShow';
import { UserList } from './UserList';
import { UserEdit } from './UserEdit';
import { UserCreate } from './UserCreate';

export default {
  list: UserList,
  show: UserShow,
  edit: UserEdit,
  create: UserCreate,
  icon: ContactsIcon,
};
