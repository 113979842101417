import React from 'react';
import { Login, LoginForm } from 'react-admin';
import * as firebaseui from 'firebaseui';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import { InferProps } from 'prop-types';
import ForgotPasswordButton from './ForgotPassword';
import { firebaseApp } from '../firebase';

// Configure FirebaseUI.
const uiConfig: firebaseui.auth.Config = {
  // Popup signin flow rather than redirect flow.
  signInFlow: firebase.auth().isSignInWithEmailLink(window.location.href)
    ? 'redirect'
    : 'popup',
  // Redirect to /signedIn after sign in is successful.
  // Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '#/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.GithubAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      // Allow the user the ability to complete sign-in cross device,
      // including the mobile apps specified in the ActionCodeSettings
      // object below.
      forceSameDevice: false,
    },
  ],

  callbacks: {
    // signInSuccessWithAuthResult: () => false,
    // // Optional callbacks in order to get Access
    // // Token from Google,Facebook,... etc
    // signInSuccessWithAuthResult: (
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   result: any
    // ): boolean => {
    //   const { credential } = result;
    //   console.log(result);
    //
    //   // The signed-in user info.
    //   const { user } = result;
    //   // This gives you a Facebook Access Token.
    //   // You can use it to access the Facebook API.
    //   const { accessToken } = credential;
    //   console.log(accessToken);
    //
    //   console.log({ result, user, accessToken });
    //
    //   return true;
    // },
  },
};

function SignInScreen() {
  return (
    <StyledFirebaseAuth
      firebaseAuth={firebase.auth(firebaseApp)}
      uiConfig={uiConfig}
    />
  );
}

type CustomLoginFormProps = InferProps<typeof CustomLoginForm>;

function CustomLoginForm(props: CustomLoginFormProps) {
  return (
    <div>
      <SignInScreen />
      <ForgotPasswordButton />
    </div>
  );
}

CustomLoginForm.propTypes = {
  ...LoginForm.propTypes,
};

type FirebaseLoginProps = InferProps<typeof FirebaseLogin>;

function FirebaseLogin(props: FirebaseLoginProps) {
  return (
    <Login {...props}>
      <CustomLoginForm {...props} />
    </Login>
  );
}

FirebaseLogin.propTypes = {
  ...Login.propTypes,
  ...CustomLoginForm.propTypes,
};

export default FirebaseLogin;
