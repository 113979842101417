import companies from './companies';
import teams from './teams';
import users from './users';
import onboardings from './onboardings';

export const resources = Object.freeze({
  companies,
  teams,
  users,
  onboardings,
})
