import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { OnboardingCreate } from './OnboardingCreate';
import { OnboardingEdit } from './OnboardingEdit';
import { OnboardingList } from './OnboardingList';

export default {
  create: OnboardingCreate,
  edit: OnboardingEdit,
  list: OnboardingList,
  icon: ArrowForwardIcon,
};
