import * as React from 'react';
import {
  AutocompleteInput,
  EditBase,
  EditProps,
  FormWithRedirect,
  ReferenceInput,
  TextInput,
  Toolbar,
  useEditContext,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import omit from 'lodash/omit';

// import { Avatar } from './Avatar';
import { TeamAside } from './TeamAside';
import { Team } from '../types';
import { formOmittedProps } from '../form-utils';

function Spacer() {
  return <Box width={20} component="span" />;
}

function TeamEditContent() {
  const { record, loaded, save } = useEditContext<Team>();
  if (!loaded || !record) return null;
  return (
    <Box mt={2} display="flex" justifyContent="center">
      <Box>
        <FormWithRedirect
          record={record}
          redirect="show"
          save={save}
          render={(formProps) => (
            <Card>
              <CardContent>
                <Box flex="1" mt={-1}>
                  <TextInput source="title" />
                  <Spacer />
                  <ReferenceInput source="company_id" reference="companies">
                    <AutocompleteInput optionText="name" />
                  </ReferenceInput>

                  <Box mt={2} width={430}>
                    <TextInput source="background" multiline fullWidth />
                  </Box>
                </Box>
              </CardContent>
              <Toolbar {...omit(formProps, formOmittedProps)} />
            </Card>
          )}
        />
      </Box>
      <TeamAside record={record} link="show" />
    </Box>
  );
}

export function TeamEdit(props: EditProps) {
  return (
    <EditBase {...props}>
      <TeamEditContent />
    </EditBase>
  );
}
