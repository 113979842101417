/* eslint react/jsx-key: off */
import * as React from 'react';
import { useParams } from 'react-router';
import {
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  required,
  List,
  Datagrid,
  ResourceContextProvider,
  EditButton,
  TranslatableInputs,
  useEditContext,
  FormWithRedirect,
  Toolbar,
  EditProps,
  EditBase,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import { omit } from 'lodash';
import { Onboarding } from '../types';

function OnboardingEditContent() {
  const { record, loaded, save } = useEditContext<Onboarding>();
  if (!loaded || !record) return null;
  return (
    <Box mt={2} display="flex" justifyContent="center">
      <Box>
        <FormWithRedirect
          record={record}
          redirect="list"
          save={save}
          render={(formProps) => (
            <Card>
              <CardContent>
                <Box flex="1" mt={-1}>
                  <TextInput source="index" />

                  <TextInput source="title" />
                </Box>
              </CardContent>
              <Toolbar
                {...omit(formProps, [
                  // FIXME Not super user friendly way to remove warnings
                  'dirtyFields',
                  'dirtyFieldsSinceLastSubmit',
                  'dirtySinceLastSubmit',
                  'hasSubmitErrors',
                  'hasValidationErrors',
                  'initialValues',
                  'modifiedSinceLastSubmit',
                  'submitError',
                  'submitErrors',
                  'submitFailed',
                  'submitSucceeded',
                  'submitting',
                  'valid',
                ])}
              />
            </Card>
          )}
        />
      </Box>
      {/* <TeamAside record={record} link="show" /> */}
    </Box>
  );
}

export function OnboardingEdit(props: EditProps) {
  return (
    <EditBase {...props}>
      <OnboardingEditContent />
    </EditBase>
  );
}
