import * as React from 'react';
import { omit } from 'ramda';
import {
  AutocompleteInput,
  BooleanInput,
  CreateBase,
  CreateProps,
  FormWithRedirect,
  ReferenceInput,
  required,
  TextInput,
  Toolbar,
  useCreateContext,
} from 'react-admin';
import { Avatar, Box, Card, CardContent, Divider } from '@material-ui/core';

import { User } from '../types';
import {formOmittedProps, formOmittedPropsType} from '../form-utils';

function Spacer() {
  return <Box width={20} component="span" />;
}

export function UserCreate(props: CreateProps) {
  return (
    <CreateBase
      {...props}
      transform={(data: User) => ({
        ...data,
        last_seen: new Date(),
        tags: [],
      })}>
      <UserCreateContent />
    </CreateBase>
  );
}

function UserCreateContent() {
  const { save, record } = useCreateContext();
  const formRender = <T,>(
    formProps: Omit<T, formOmittedPropsType>,
  ) => (
    <Card>
      <CardContent>
        <Box>
          <Box display="flex">
            <Box mr={2}>
              <Avatar />
            </Box>
            <Box flex="1" mt={-1}>
              <Box display="flex">
                <TextInput source="first_name" validate={[required()]} />
                <Spacer />
                <TextInput source="last_name" validate={[required()]} />
              </Box>
              <Box display="flex">
                <TextInput source="title" />
                <Spacer />
                <ReferenceInput source="company_id" reference="companies">
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              </Box>
              <Divider />
              <Box mt={2} width={430}>
                <TextInput source="email" fullWidth />
              </Box>
              <Box display="flex">
                <TextInput source="phone_number1" />
                <Spacer />
                <TextInput source="phone_number2" />
              </Box>
              <Divider />
              <Box mt={2} width={430}>
                <TextInput source="background" multiline fullWidth />
                <TextInput source="avatar" fullWidth />
                <BooleanInput source="has_newsletter" />
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
      <Toolbar {...omit([...formOmittedProps, 'save'])(formProps)} />
    </Card>
  );
  return (
    <Box mt={2} display="flex">
      <Box flex="1">
        <FormWithRedirect
          redirect="show"
          record={record}
          save={save}
          render={formRender}
        />
      </Box>
    </Box>
  );
}
