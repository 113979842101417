import LocationCityIcon from '@material-ui/icons/LocationCity';

import { CompanyList } from './CompanyList';
import { CompanyCreate } from './CompanyCreate';
import { CompanyShow } from './CompanyShow';
import { CompanyEdit } from './CompanyEdit';

export default {
  list: CompanyList,
  create: CompanyCreate,
  edit: CompanyEdit,
  show: CompanyShow,
  icon: LocationCityIcon,
};
