import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {
  MenuItemLink,
  MenuProps,
  ReduxState,
  useGetIdentity,
  usePermissions,
  useTranslate,
} from 'react-admin';

import companies from '../companies';
import teams from '../teams';
import users from '../users';
import onboarding from '../onboardings';

import { AppState } from '../types';
import {FirebaseUserIdentity} from "../authProvider";
import {hasAccess} from "../libs/ra-auth-acl";

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

function Menu({ dense = false }: MenuProps) {
  const { identity } = useGetIdentity();
  const { permissions } = usePermissions();
  console.log(permissions)

  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
  });
  const translate = useTranslate();
  const open = useSelector((s: ReduxState) => s.admin.ui.sidebarOpen);
  useSelector((s: AppState) => s.theme); // force rerender on theme change
  const classes = useStyles();

  const handleToggle = (menu: MenuName) => {
    setState((s) => ({ ...s, [menu]: !s[menu] }));
  };

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}>
      {' '}
      {hasAccess(permissions, 'companies') && (
        <MenuItemLink
          to={{
            pathname: '/companies',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('resources.companies.name', {
            smart_count: 2,
          })}
          leftIcon={<companies.icon />}
          dense={dense}
        />
      )}
      {hasAccess(permissions, 'teams') && (
        <MenuItemLink
          to={{
            pathname: '/teams',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('resources.teams.name', {
            smart_count: 2,
          })}
          leftIcon={<teams.icon />}
          dense={dense}
        />
      )}
      {hasAccess(permissions, 'users') && (
        <MenuItemLink
          to={{
            pathname: '/users',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('resources.users.name', {
            smart_count: 2,
          })}
          leftIcon={<users.icon />}
          dense={dense}
        />
      )}
      {hasAccess(permissions, 'onboardings') && (
        <MenuItemLink
          to={{
            pathname: '/onboardings',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('Onboarding Steps', {
            smart_count: 2,
          })}
          leftIcon={<onboarding.icon />}
          dense={dense}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 200,
  },
  closed: {
    width: 55,
  },
}));

export default Menu;
