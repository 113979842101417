import * as React from 'react';
import { useState, ChangeEvent } from 'react';
import {
  ShowBase,
  ShowProps,
  TextField,
  ReferenceManyField,
  SelectField,
  useShowContext,
  useRecordContext,
  useListContext,
  useTranslate,
} from 'react-admin';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Tabs,
  Tab,
  Divider,
} from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Link as RouterLink } from 'react-router-dom';
import { formatDistance } from 'date-fns';

import { Avatar } from '../users/Avatar';
import { Status } from '../misc/Status';
import { TagsList } from '../users/TagsList';
import { sizes } from './sizes';
import { LogoField } from './LogoField';
import { CompanyAside } from './CompanyAside';
import { Company, Deal, User } from '../types';
import { CompanyAvatar } from './CompanyAvatar';

export function CompanyShow(props: ShowProps) {
  const translate = useTranslate();
  return (
    <ShowBase {...props}>
      <CompanyShowContent />
    </ShowBase>
  );
}

function CompanyShowContent() {
  const translate = useTranslate();
  const { record, loaded } = useShowContext<Company>();
  const [value, setValue] = useState(0);
  const handleChange = (event: ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };
  if (!loaded || !record) return null;

  return (
    <Box mt={2} display="flex">
      <Box flex="1">
        <Card>
          <CardContent>
            <Box display="flex" mb={1}>
              <CompanyAvatar record={record} />
              {/* <LogoField record={record as any}/> */}
              <Box ml={2} flex="1">
                <Typography variant="h5">
                  {record.name}
                </Typography>
                <Typography variant="body2">
                  <TextField
                    source={translate('resources.companies.fields.sector')}
                  />
                  ,{' '}
                  <SelectField
                    source={translate('resources.companies.fields.size')}
                    choices={sizes}
                  />
                </Typography>
              </Box>
            </Box>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}>
              {record.nb_users && (
                <Tab
                  label={translate(
                    `${
                      record.nb_users === 1
                        ? '1 User'
                        : `${record.nb_users} Users`
                    }`,
                  )}
                />
              )}
            </Tabs>
            <Divider />
            <TabPanel value={value} index={0}>
              <ReferenceManyField
                reference="users"
                target="company_id"
                sort={{ field: 'last_name', order: 'ASC' }}>
                <UsersIterator />
              </ReferenceManyField>
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
              <ReferenceManyField
                reference="deals"
                target="company_id"
                sort={{ field: 'name', order: 'ASC' }}
              >
                <DealsIterator />
              </ReferenceManyField>
            </TabPanel> */}
          </CardContent>
        </Card>
      </Box>
      <CompanyAside record={record} />
    </Box>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}>
      {children}
    </div>
  );
}

function UsersIterator() {
  const { data, ids, loaded } = useListContext<User>();
  const record = useRecordContext();

  const now = Date.now();
  if (!loaded) return null;
  return (
    <Box>
      <List>
        {ids.map((id) => {
          const user = data[id];
          return (
            <ListItem
              button
              key={id}
              component={RouterLink}
              to={`/users/${id}/show`}>
              <ListItemAvatar>
                <Avatar record={user} />
              </ListItemAvatar>
              <ListItemText primary={`${user.first_name} ${user.last_name}`} />
              <ListItemSecondaryAction>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="span">
                  last activity {formatDistance(new Date(user.last_seen), now)}{' '}
                  ago <Status status={user.status} />
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <Box textAlign="center" mt={1}>
        <CreateRelatedUserButton record={record} />
      </Box>
    </Box>
  );
}

function CreateRelatedUserButton({ record }: any) {
  const translate = useTranslate();

  return (
    <Button
      component={RouterLink}
      to={{
        pathname: '/users/create',
        state: { record: { company_id: record.id } },
      }}
      color="primary"
      variant="contained"
      size="small"
      startIcon={<PersonAddIcon />}>
      {translate('resources.companies.fields.add_user')}
    </Button>
  );
}

// function DealsIterator() {
//   const { data, ids, loaded } = useListContext<Deal>();

//   const now = Date.now();
//   if (!loaded) return null;
//   return (
//     <Box>
//       <List>
//         {ids.map((id) => {
//           const deal = data[id];
//           return (
//             <ListItem
//               button
//               key={id}
//               component={RouterLink}
//               to={`/deals/${id}/show`}
//             >
//               <ListItemText
//                 primary={deal.name}
//                 secondary={
//                   <>
//                     {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
//                     {/* @ts-ignore */}
//                     {stageNames[deal.stage]},{' '}
//                     {deal.amount.toLocaleString('en-US', {
//                       notation: 'compact',
//                       style: 'currency',
//                       currency: 'USD',
//                       currencyDisplay: 'narrowSymbol',
//                       minimumSignificantDigits: 3,
//                     })}
//                     , {deal.type}
//                   </>
//                 }
//               />
//               <ListItemSecondaryAction>
//                 <Typography
//                   variant="body2"
//                   color="textSecondary"
//                   component="span"
//                 >
//                   last activity {formatDistance(new Date(deal.updated_at), now)}{' '}
//                   ago{' '}
//                 </Typography>
//               </ListItemSecondaryAction>
//             </ListItem>
//           );
//         })}
//       </List>
//     </Box>
//   );
// }
