import * as React from 'react';
import {
  ShowBase,
  ShowProps,
  TextField,
  ReferenceField,
  ReferenceManyField,
  useShowContext,
} from 'react-admin';
import { Box, Card, CardContent, Divider, Typography } from '@material-ui/core';
import TeamsIcon from '@material-ui/icons/Group';

// import { Avatar } from './Avatar';
import { TeamAside } from './TeamAside';
import { LogoField } from '../companies/LogoField';
import companies from '../companies';
import { Team } from '../types';

export function TeamShow(props: ShowProps) {
  return (
    <ShowBase {...props}>
      <TeamShowContent />
    </ShowBase>
  );
}

function TeamShowContent() {
  const { record, loaded } = useShowContext<Team>();
  if (!loaded || !record) return null;
  return (
    <Box display="flex" justifyContent="center">
      <Box mt={2} display="flex" width={500}>
        <Box flex="1">
          <Card>
            <CardContent>
              <Box display="flex">
                {/* <Avatar record={record} /> */}
                <TeamsIcon />
                <Box ml={2} flex="1">
                  <Typography variant="h5">{record.title}</Typography>
                  <Typography variant="body2">
                    {/* {record.title} at{' '} */}
                    <ReferenceField
                      source="company_id"
                      reference="companies"
                      link="show"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                  </Typography>
                </Box>
                <Box>
                  <ReferenceField
                    source="company_id"
                    reference="companies"
                    link="show"
                  >
                    {/* <LogoField /> */}
                    <companies.icon />
                  </ReferenceField>
                </Box>
              </Box>
              <Divider />

              <Box display="flex" justifyContent="center" mt={3}>
                <TeamAside variant="contained" record={record} />
              </Box>
              <Box mt={2}>
                <Typography variant="subtitle2">Background</Typography>
                <Divider />
                <Typography component="p">
                  {record
                    ? record.background
                    : 'Edit your team to add a background'}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
        {/* <TeamAside record={record} /> */}
      </Box>
    </Box>
  );
}
