import * as React from 'react';
import { useState } from 'react';
import { Link as MuiLink, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UsersIcon from '@material-ui/icons/AccountCircle';
import { linkToRecord, SelectField, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';

import { sectors } from './sectors';
import { CompanyAvatar } from './CompanyAvatar';
import { Company } from '../types';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 200,
    width: 193.5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1em',
  },
  identity: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  name: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  stats: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  singleStat: {
    display: 'flex',
    alignItems: 'center',
  },
  statIcon: {
    marginRight: theme.spacing(1),
  },
}));

export function CompanyCard({ record }: { record: Company }) {
  const translate = useTranslate();
  const classes = useStyles();
  const [elevation, setElevation] = useState(1);
  return (
    <MuiLink
      component={Link}
      to={linkToRecord('/companies', record.id, 'show')}
      underline="none"
      onMouseEnter={() => setElevation(3)}
      onMouseLeave={() => setElevation(1)}>
      <Paper className={classes.paper} elevation={elevation}>
        <div className={classes.identity}>
          <CompanyAvatar record={record} />
          <div className={classes.name}>
            <Typography variant="subtitle2">{record.name}</Typography>
            <SelectField
              color="textSecondary"
              source={translate('resources.companies.fields.sector')}
              choices={sectors}
              record={record}
            />
          </div>
        </div>
        <div className={classes.stats}>
          <div className={classes.singleStat}>
            <UsersIcon color="disabled" className={classes.statIcon} />
            {record.nb_users > 0 && (
              <>
                <Typography variant="subtitle2" style={{ marginBottom: -8 }}>
                  {record.nb_users || 0}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {translate(record.nb_users > 1 ? 'users' : 'user')}
                </Typography>
              </>
            )}
            {!record.nb_users && (
              <Typography variant="caption" color="textSecondary">
                {translate('resources.companies.fields.no_users')}
              </Typography>
            )}
          </div>
        </div>
      </Paper>
    </MuiLink>
  );
}
