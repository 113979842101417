export const formOmittedProps = [
  // FIXME Not super user friendly way to remove warnings
  'dirtyFields',
  'dirtyFieldsSinceLastSubmit',
  'dirtySinceLastSubmit',
  'hasSubmitErrors',
  'hasValidationErrors',
  'initialValues',
  'modifiedSinceLastSubmit',
  'submitError',
  'submitErrors',
  'submitFailed',
  'submitSucceeded',
  'submitting',
  'valid',
]

export type formOmittedPropsType =
  'dirtyFields' |
  'dirtyFieldsSinceLastSubmit' |
  'dirtySinceLastSubmit' |
  'hasSubmitErrors' |
  'hasValidationErrors' |
  'initialValues' |
  'modifiedSinceLastSubmit' |
  'submitError' |
  'submitErrors' |
  'submitFailed' |
  'submitSucceeded' |
  'submitting' |
  'valid';
