import * as React from 'react';
import {
  DateField,
  EditButton,
  ShowButton,
  TextField,
  useTranslate,
} from 'react-admin';
import { Box, Divider, Link, Typography } from '@material-ui/core';

import { Company } from '../types';

export function CompanyAside({
  record,
  link = 'edit',
}: {
  record?: Company;
  link?: string;
}) {
  const translate = useTranslate();
  return record ? (
    <Box ml={4} width={250} minWidth={250}>
      <Box textAlign="center" mb={2}>
        {link === 'edit' ? (
          <EditButton
            basePath="/companies"
            record={record}
            label={translate('resources.companies.fields.edit_company')}
          />
        ) : (
          <ShowButton
            basePath="/companies"
            record={record}
            label={translate('resources.companies.fields.show_company')}
          />
        )}
      </Box>

      <Typography variant="subtitle2">
        {translate('resources.companies.fields.company_info')}
      </Typography>
      <Divider />

      <Box mt={2}>
        Website:{' '}
        {record.website && <Link href={record.website}>{record.website}</Link>}
        <br />
        LinkedIn:{' '}
        {record.linkedIn && <Link href={record.linkedIn}>LinkedIn</Link>}
      </Box>

      <Box mt={1}>
        <TextField source="phone_number" record={record} />{' '}
        <Typography variant="body2" color="textSecondary" component="span">
          {translate('resources.companies.fields.main')}
        </Typography>
      </Box>

      <Box mt={1} mb={3}>
        <TextField source={translate('resources.companies.fields.address')} />
        <br />
        <TextField source={translate('resources.companies.fields.city')} />{' '}
        <TextField source={translate('resources.companies.fields.zipcode')} />{' '}
        <TextField
          source={translate('resources.companies.fields.state_abbr')}
        />
      </Box>

      <Typography variant="subtitle2">
        {translate('resources.companies.fields.background')}
      </Typography>
      <Divider />

      <Box mt={1}>
        <Typography variant="body2" color="textSecondary" component="span">
          {translate('resources.companies.fields.added_on')}
        </Typography>{' '}
        <DateField
          source="created_at"
          options={{ year: 'numeric', month: 'long', day: 'numeric' }}
          color="textSecondary"
        />
        <br />
        <Typography component="span" variant="body2" color="textSecondary">
          {translate('resources.companies.fields.followed_by')}
        </Typography>{' '}
        {/* <ReferenceField
          resource="companies"
          source="sales_id"
          reference="sales"
        >
          <FunctionField<Sale>
            source={translate('last_name')}
            render={(r) =>
              r
                ? `${translate(`${r.first_name}`)} ${translate(
                    `${r.last_name}`
                  )}`
                : ''
            }
          />
        </ReferenceField> */}
      </Box>
    </Box>
  ) : null;
}
