import { retry } from 'ts-retry-promise';
import { FirebaseDataProvider, RAFirebaseOptions } from 'react-admin-firebase';
import {
  IDataProvider
} from 'react-admin-firebase/dist/providers/DataProvider';
import * as ra from 'react-admin-firebase/src/misc/react-admin-models';
import type {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import firebaseConfig from './conf/firebase';
import { authProvider } from './authProvider';
import { firebaseApp, type IdTokenResult } from './firebase';

export const dataProviderOptions: RAFirebaseOptions = {
  logging: true,
  app: firebaseApp,
  rootRef: 'booth/tmp',
  // watch: ['posts'],
  // dontwatch: ['comments'];
  persistence: 'local',
  // disableMeta: true
  dontAddIdFieldToDoc: true,
  // lazyLoading: {
  //   enabled: true,
  // },
  firestoreCostsLogger: {
    enabled: true,
  },
};
const getIdTokenResult = () => {
  const getIdToken = () =>
    authProvider.getAuthUser().then((user) => user.getIdTokenResult(true));

  const strategy = (): Promise<IdTokenResult> =>
    retry(() => Promise.resolve(localStorage.getItem('auth')), {
      until: (r) => r !== undefined,
    }).then(() => getIdToken());

  return retry(() => strategy(), {
    retries: 100,
    backoff: 'EXPONENTIAL',
    delay: 500,
    until: (r) => r.claims?.booth,
  });
};

export enum AsyncDataProviderState {
  READY,
  LOADING,
  FAILED,
}

export interface AsyncDataProvider {
  providerState: () => Promise<AsyncDataProviderState>;
}

export const dataProvider = () => {
  let state = AsyncDataProviderState.LOADING;
  let firebaseProvider: IDataProvider;
  const getProvider = (): Promise<IDataProvider> => {
    if (firebaseProvider) return Promise.resolve(firebaseProvider);

    return getIdTokenResult().then((t) => {
      firebaseProvider = FirebaseDataProvider(firebaseConfig, {
        ...dataProviderOptions,
        rootRef: `booth/${t.claims?.booth}`,
      });
      state = AsyncDataProviderState.READY;
      return firebaseProvider;
    });
  };

  const provider: AsyncDataProvider & DataProvider = {
    providerState: () => Promise.resolve(state),

    create<RecordType extends ra.Record = ra.Record>(
      resource: string,
      params: CreateParams,
    ): Promise<CreateResult<RecordType>> {
      return getProvider().then((p) => p.create<RecordType>(resource, params));
    },
    delete<RecordType extends ra.Record = ra.Record>(
      resource: string,
      params: DeleteParams,
    ): Promise<DeleteResult<RecordType>> {
      return getProvider().then((p) => p.delete<RecordType>(resource, params));
    },
    deleteMany(
      resource: string,
      params: DeleteManyParams,
    ): Promise<DeleteManyResult> {
      return getProvider().then((p) => p.deleteMany(resource, params));
    },
    getList<RecordType extends ra.Record = ra.Record>(
      resource: string,
      params: GetListParams,
    ): Promise<GetListResult<RecordType>> {
      return getProvider().then((p) => p.getList<RecordType>(resource, params));
    },
    getMany<RecordType extends ra.Record = ra.Record>(
      resource: string,
      params: GetManyParams,
    ): Promise<GetManyResult<RecordType>> {
      return getProvider().then((p) => p.getMany<RecordType>(resource, params));
    },
    getManyReference<RecordType extends ra.Record = ra.Record>(
      resource: string,
      params: GetManyReferenceParams,
    ): Promise<GetManyReferenceResult<RecordType>> {
      return getProvider().then((p) =>
        p.getManyReference<RecordType>(resource, params),
      );
    },
    getOne<RecordType extends ra.Record = ra.Record>(
      resource: string,
      params: GetOneParams,
    ): Promise<GetOneResult<RecordType>> {
      return getProvider().then((p) => p.getOne<RecordType>(resource, params));
    },
    update<RecordType extends ra.Record = ra.Record>(
      resource: string,
      params: UpdateParams,
    ): Promise<UpdateResult<RecordType>> {
      return getProvider().then((p) => p.update<RecordType>(resource, params));
    },
    updateMany(
      resource: string,
      params: UpdateManyParams,
    ): Promise<UpdateManyResult> {
      return getProvider().then((p) => p.updateMany(resource, params));
    },
  };

  return provider;
};
