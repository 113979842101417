import * as React from 'react';
import {
  List as RaList,
  ListProps,
  SimpleListLoading,
  ReferenceField,
  TextField,
  useListContext,
  ExportButton,
  SortButton,
  TopToolbar,
  CreateButton,
  Pagination,
  useGetIdentity,
} from 'react-admin';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';

import { Avatar } from './Avatar';
import { Status } from '../misc/Status';
import { User } from '../types';

function UserListContent() {
  const { data, ids, loaded, onToggleItem, selectedIds } =
    useListContext<User>();
  const now = Date.now();
  if (!loaded) {
    return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
  }

  return (
    <List>
      {ids.map((id) => {
        const user = data[id];
        return (
          <ListItem button key={id} component={Link} to={`/users/${id}/show`}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={selectedIds.includes(id)}
                tabIndex={-1}
                disableRipple
                onClick={(e) => {
                  e.stopPropagation();
                  onToggleItem(id);
                }}
              />
            </ListItemIcon>
            <ListItemAvatar>
              <Avatar record={user} />
            </ListItemAvatar>
            <ListItemText
              primary={`${user.first_name} ${user.last_name}`}
              secondary={
                <>
                  {user.title} at{' '}
                  <ReferenceField
                    record={user}
                    source="company_id"
                    reference="companies"
                    basePath="/companies"
                    link={false}>
                    <TextField source="name" />
                  </ReferenceField>{' '}
                  {user.nb_notes && `- ${user.nb_notes} notes `}
                </>
              }
            />
            <ListItemSecondaryAction>
              <Typography variant="body2" color="textSecondary">
                last activity {formatDistance(new Date(user.last_seen), now)}{' '}
                ago <Status status={user.status} />
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}

const useActionStyles = makeStyles((theme) => ({
  createButton: {
    marginLeft: theme.spacing(2),
  },
}));

function UserListActions() {
  const classes = useActionStyles();
  return (
    <TopToolbar>
      <SortButton fields={['last_name', 'first_name', 'last_seen']} />
      <ExportButton />
      <CreateButton
        basePath="/users"
        variant="contained"
        label="resources.users.fields.new_user"
        className={classes.createButton}
      />
    </TopToolbar>
  );
}

export function UserList(props: ListProps) {
  const { identity } = useGetIdentity();
  return identity ? (
    <RaList
      {...props}
      actions={<UserListActions />}
      // aside={<UserListFilter />}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      filterDefaultValues={{ sales_id: identity?.id }}
      sort={{ field: 'last_seen', order: 'DESC' }}>
      <UserListContent />
    </RaList>
  ) : null;
}
