import * as React from 'react';
import {
  Edit,
  EditProps,
  ReferenceInput,
  SimpleForm,
  TextInput,
  SelectInput,
  useRecordContext,
  required,
  useTranslate,
} from 'react-admin';
import { Box, CardContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { CompanyAside } from './CompanyAside';
import { LogoField } from './LogoField';
import { sectors } from './sectors';
import { sizes } from './sizes';

const useStyles = makeStyles({
  inline: {
    display: 'inline-block',
    marginRight: '1em',
  },
  textTransform: {
    textTransform: 'capitalize',
  },
});

export function CompanyEdit(props: EditProps) {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Edit {...props} aside={<CompanyAside link="show" />} actions={false}>
      <SimpleForm component={CustomLayout} redirect="show">
        <TextInput
          source={translate('resources.companies.fields.name')}
          validate={required()}
          fullWidth
          formClassName={classes.textTransform}
        />
        <SelectInput
          source={translate('resources.companies.fields.sector')}
          choices={sectors}
          formClassName={`${classes.inline} ${classes.textTransform}`}
        />
        <SelectInput
          source={translate('resources.companies.fields.size')}
          choices={sizes}
          formClassName={`${classes.inline} ${classes.textTransform}`}
        />
        <CustomDivider />
        <TextInput
          source={translate('resources.companies.fields.address')}
          fullWidth
          helperText={false}
          formClassName={classes.textTransform}
        />
        <TextInput
          source={translate('resources.companies.fields.city')}
          formClassName={`${classes.inline} ${classes.textTransform}`}
        />
        <TextInput
          source={translate('resources.companies.fields.zipcode')}
          formClassName={`${classes.inline} ${classes.textTransform}`}
        />
        <TextInput
          source={translate('resources.companies.fields.state_abbr')}
          formClassName={`${classes.inline} ${classes.textTransform}`}
        />
        <CustomDivider />
        <TextInput
          source={translate('resources.companies.fields.website')}
          fullWidth
          helperText={false}
          formClassName={classes.textTransform}
        />
        {/* <TextInput source="linkedIn" fullWidth helperText={false} /> */}
        <TextInput
          source={translate('resources.companies.fields.logo')}
          fullWidth
          formClassName={classes.textTransform}
        />
        <CustomDivider />
        <TextInput
          source={translate('resources.companies.fields.phone_number')}
          formClassName={`${classes.inline} ${classes.textTransform}`}
          helperText={false}
        />
        <ReferenceInput
          source="sales_id"
          reference="sales"
          label={translate('resources.companies.fields.account_manager')}
          formClassName={classes.inline}
          helperText={false}
        >
          <SelectInput
            optionText={(sales: any) =>
              translate(`${sales.first_name} ${sales.last_name}`)
            }
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}

function CustomLayout(props: any) {
  const record = useRecordContext(props);
  return (
    <CardContent>
      <Box display="flex" sx={{ justifyContent: 'center' }}>
        <LogoField record={record as any} />
        <Box ml={2} flex="1" maxWidth={796}>
          {props.children}
        </Box>
      </Box>
    </CardContent>
  );
}

function CustomDivider() {
  return (
    <Box mb={2}>
      <Divider />
    </Box>
  );
}
