/* eslint-disable import/no-anonymous-default-export */
import * as React from 'react';
import {
  List as RaList,
  ListProps,
  TextField,
  TopToolbar,
  CreateButton,
  Pagination,
  Datagrid,
  EditButton,
  DeleteWithUndoButton,
  useTranslate,
} from 'react-admin';
import { List, Typography, Box } from '@material-ui/core';

function OnboardingListActions() {
  const translate = useTranslate();
  return (
    <TopToolbar>
      <Box display="flex" flexDirection="column" width={700} mb={5}>
        <Box>
          <Typography variant="h2" component="h2" align="center">
            {translate('resources.onboarding.fields.header_title')}
          </Typography>
          <Typography variant="body1" component="p" align="center">
            lore
            {translate('resources.onboarding.fields.header_body')}
          </Typography>
        </Box>
      </Box>
    </TopToolbar>
  );
}

export function OnboardingList(props: ListProps) {
  return (
    <Box display="grid" justifyContent="center">
      <RaList
        {...props}
        actions={<OnboardingListActions />}
        perPage={25}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        sort={{ field: 'title', order: 'ASC' }}
      >
        <Box m={5}>
          <OnboardingListItem />
        </Box>
      </RaList>
    </Box>
  );
}

function OnboardingListItem() {
  return (
    <List>
      <Datagrid>
        <TextField source="index" label="Step No." aria-required />
        <TextField source="title" label="Steps" />
        <EditButton label="Edit" />
        <DeleteWithUndoButton label="Delete" />
      </Datagrid>
      <Box mt={3}>
        <CreateButton label="Add Step" fullWidth />
      </Box>
    </List>
  );
}
