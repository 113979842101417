import * as React from 'react';
import {
  CreateBase,
  CreateProps,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  FormWithRedirect,
  Toolbar,
  required,
  useCreateContext,
} from 'react-admin';
import { Card, CardContent, Box } from '@material-ui/core';

import { Team } from '../types';

function Spacer() {
  return <Box width={20} component="span" />;
}

export function TeamCreate(props: CreateProps) {
  return (
    <CreateBase
      {...props}
      transform={(data: Team) => ({
        ...data,
      })}
    >
      <TeamCreateContent />
    </CreateBase>
  );
}

function TeamCreateContent() {
  const { save, record } = useCreateContext();
  return (
    <Box mt={2} display="grid" justifyContent="center">
      <Box>
        <FormWithRedirect
          redirect="list"
          record={record as any}
          save={save}
          render={(formProps) => (
            <Card>
              <CardContent>
                <Box display="flex" flexDirection="column">
                  <TextInput source="title" />
                  <Spacer />
                  <ReferenceInput source="company_id" reference="companies">
                    <AutocompleteInput optionText="name" />
                  </ReferenceInput>
                </Box>
              </CardContent>
              <Toolbar {...formProps} />
            </Card>
          )}
        />
      </Box>
    </Box>
  );
}
