import * as React from 'react';
import { Admin } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import themeReducer from './themeReducer';
import { Layout } from './layout';
import customRoutes from './routes';
import englishMessages from './i18n/en';
import companies from './companies';
import teams from './teams';
import users from './users';
import onboardings from './onboardings';

import FirebaseLogin from './layout/FirebaseLogin';
import { authProvider } from './authProvider';
import { dataProvider } from './dataProvider';
import { ResourceWithPermissions } from './libs/ra-auth-acl';

const i18nProvider = polyglotI18nProvider((locale: string) => {
  if (locale === 'fr') {
    return import('./i18n/fr').then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, 'en');

function App() {
  return (
    <Admin
      title=""
      dataProvider={dataProvider()}
      customReducers={{ theme: themeReducer }}
      customRoutes={customRoutes}
      authProvider={authProvider}
      // dashboard={Dashboard}
      loginPage={FirebaseLogin}
      layout={Layout}
      i18nProvider={i18nProvider}
      disableTelemetry>
      {(permissions) => [
        <ResourceWithPermissions
          name="companies"
          permissions={permissions}
          {...companies}
        />,
        <ResourceWithPermissions
          name="teams"
          permissions={permissions}
          {...teams}
        />,
        <ResourceWithPermissions
          name="users"
          permissions={permissions}
          {...users}
        />,
        <ResourceWithPermissions
          name="teams"
          permissions={permissions}
          {...teams}
        />,
        <ResourceWithPermissions
          name="onboardings"
          permissions={permissions}
          {...onboardings}
          options={{ label: 'Onboarding Steps' }}
        />,
      ]}
    </Admin>
  );
}

export default App;
