import * as React from 'react';
import { Avatar as MuiAvatar } from '@material-ui/core';

import { User } from '../types';

export function Avatar({ record }: { record: User }) {
  return (
    <MuiAvatar src={record.avatar}>
      {record.first_name.charAt(0)}
      {record.last_name.charAt(0)}
    </MuiAvatar>
  );
}
