import * as R from 'ramda';
import { UserIdentity } from 'react-admin';
import { FirebaseAuthProvider, RAFirebaseOptions } from 'react-admin-firebase';
import { Permission } from './types';
import firebaseConfig from './conf/firebase';
import { firebaseApp } from './firebase';
import { resources } from './resources';
import { buildFullAccessFor } from './libs/ra-auth-acl';

const options: RAFirebaseOptions = {
  logging: true,
  // watch: ['customers', 'commands'],
  app: firebaseApp,
};
export const firebaseAuthProvider = FirebaseAuthProvider(
  firebaseConfig,
  options,
);

export interface FirebaseUserIdentity extends UserIdentity {
  isAdmin: boolean;
}

export const authProvider = {
  ...firebaseAuthProvider,
  async getIdentity(): Promise<FirebaseUserIdentity> {
    const { uid, displayName, photoURL } = await this.getAuthUser();
    const claims = await this.getJWTClaims();
    return {
      id: uid,
      fullName: `${displayName ?? ''}`,
      avatar: `${photoURL ?? ''}`,
      isAdmin: !!claims?.admin,
    };
  },
  async getPermissions(): Promise<Record<string, Permission> | undefined> {
    const claims = await this.getJWTClaims();
    const isAdmin = !!claims?.admin;

    if (isAdmin) return buildFullAccessFor(Object.keys(resources));

    const permissions = claims?.roles.map(
      (r: Record<string, string | string[]>): [string, Permission] => {
        const keyParts = R.dropLast(1, (r.name as string).split(':'));
        const key = keyParts.join('-');
        const perms = {
          enabled: r.permissions.includes('Enabled'),
          list: r.permissions.includes('List'),
          create: r.permissions.includes('Create'),
          edit: r.permissions.includes('Edit'),
          show: r.permissions.includes('Show'),
        };
        return [key, perms];
      },
    );
    return Object.fromEntries(new Map(permissions));
  },
};
