import * as React from 'react';
import {
  ReferenceField,
  ShowBase,
  ShowProps,
  TextField,
  useShowContext,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

import { Avatar } from './Avatar';
import { LogoField } from '../companies/LogoField';
import { User } from '../types';

export function UserShow(props: ShowProps) {
  return (
    <ShowBase {...props}>
      <UserShowContent />
    </ShowBase>
  );
}

function UserShowContent() {
  const { record, loaded } = useShowContext<User>();
  if (!loaded || !record) return null;
  return (
    <Box mt={2} display="flex">
      <Box flex="1">
        <Card>
          <CardContent>
            <Box display="flex">
              <Avatar record={record} />
              <Box ml={2} flex="1">
                <Typography variant="h5">
                  {record.first_name} {record.last_name}
                </Typography>
                <Typography variant="body2">
                  {record.title} at{' '}
                  <ReferenceField
                    source="company_id"
                    reference="companies"
                    link="show">
                    <TextField source="name" />
                  </ReferenceField>
                </Typography>
              </Box>
              <Box>
                <ReferenceField
                  source="company_id"
                  reference="companies"
                  link="show">
                  <LogoField />
                </ReferenceField>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
