/* eslint react/jsx-key: off */
import { Box, Card, CardContent } from '@material-ui/core';
import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextField,
  TextInput,
  required,
  TranslatableInputs,
  Toolbar,
  useCreateContext,
  FormWithRedirect,
  CreateBase,
  CreateProps,
} from 'react-admin';
import { Onboarding } from '../types';

export function OnboardingCreate(props: CreateProps) {
  return (
    <CreateBase
      {...props}
      transform={(data: Onboarding) => ({
        ...data,
      })}
    >
      <OnboardingCreateContent />
    </CreateBase>
  );
}

function OnboardingCreateContent() {
  const { save, record } = useCreateContext();
  return (
    <Box mt={2} display="grid" justifyContent="center">
      <Box>
        <FormWithRedirect
          redirect="list"
          record={record as any}
          save={save}
          render={(formProps) => (
            <Card>
              <CardContent>
                <>
                  <TextInput source="index" />

                  <TextInput source="title" />

                  <Toolbar {...formProps} />
                </>
              </CardContent>
            </Card>
          )}
        />
      </Box>
    </Box>
  );
}
