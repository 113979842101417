import * as React from 'react';
import {
  TextField,
  EmailField,
  DateField,
  ReferenceManyField,
  EditButton,
  ShowButton,
  useListContext,
  ReferenceField,
  FunctionField,
} from 'react-admin';
import { Box, Typography, Divider, List, ListItem } from '@material-ui/core';

export function TeamAside({ record, link = 'edit' }: any) {
  return (
    <Box ml={4} width={150} minWidth={150}>
      <Box textAlign="center" mb={2}>
        {link === 'edit' ? (
          <EditButton
            basePath="/teams"
            record={record}
            variant="contained"
            label="Edit Team"
          />
        ) : (
          <ShowButton
            basePath="/teams"
            record={record}
            variant="contained"
            label="Show Team"
          />
        )}
      </Box>

      {/* <Typography variant="subtitle2">Background</Typography>
      <Divider />

      <Box mt={2}>{record && record.background}</Box> */}
      {/* <Box mt={1} mb={3}>
        <Typography component="span" variant="body2" color="textSecondary">
          Added on
        </Typography>{' '}
        <DateField
          source="first_seen"
          options={{ year: 'numeric', month: 'long', day: 'numeric' }}
          color="textSecondary"
        />
        <br />
        <Typography component="span" variant="body2" color="textSecondary">
          Followed by
        </Typography>{' '}
        <ReferenceField resource="teams" source="sales_id" reference="sales">
          <FunctionField<Sale>
            source="last_name"
            render={(r) => (r ? `${r.first_name} ${r.last_name}` : '')}
          />
        </ReferenceField>
      </Box>

      <Box mb={3}>
        <Typography variant="subtitle2">Tags</Typography>
        <Divider />
        {/* <TagsListEdit record={record} /> */}
      {/* </Box>  */}

      {/* <ReferenceManyField resource="teams" target="team_id" reference="tasks">
        <TasksIterator />
      </ReferenceManyField> */}
    </Box>
  );
}

// function TasksIterator() {
//   const { data, ids, loading } = useListContext();
//   if (loading || ids.length === 0) return null;
//   return (
//     <Box>
//       <Typography variant="subtitle2">Tasks</Typography>
//       <Divider />

//       <List>
//         {ids.map((id) => {
//           const task = data[id];
//           return (
//             <ListItem key={id} disableGutters>
//               <Box>
//                 <Typography variant="body2">{task.text}</Typography>
//                 <Typography variant="body2" color="textSecondary">
//                   due <DateField source="due_date" record={task} />
//                 </Typography>
//               </Box>
//             </ListItem>
//           );
//         })}
//       </List>
//     </Box>
//   );
// }
