import * as R from 'ramda';
import { Permission } from '../../types';

export const hasAccess = (
  permissions: Record<string, Permission>,
  ...askedPermissions: string[]
) =>
  askedPermissions.every((permission) =>
    R.pathOr(false, permission.split("."))(permissions),
  );

export const buildFullAccessFor = (resources: string[]) =>
  resources.reduce((acc: Record<string, Permission>, resource) => {
    acc[resource] = {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
    };

    return acc;
  }, {});
