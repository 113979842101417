/* eslint-disable import/no-anonymous-default-export */
import * as React from 'react';
import {
  List as RaList,
  ListProps,
  ReferenceField,
  TextField,
  TopToolbar,
  CreateButton,
  Pagination,
  useGetIdentity,
  SortButton,
  ExportButton,
  SimpleListLoading,
  useListContext,
  useTranslate,
} from 'react-admin';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  Typography,
  Box,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';

import { Status } from '../misc/Status';
// import { TagsList } from './TagsList';
import { TeamListFilter } from './TeamListFilter';
import { Team } from '../types';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 'auto',
    boxSizing: 'border-box',
  },
  listItem: {
    width: '500px',
    border: '1px solid #ccc',
    height: '50px',
    borderRadius: '5px',
    marginTop: '8px',
  },
  raList: {
    border: 0,
    borderRadius: 0,
    backgroundColor: 'inherit',
    color: 'inherit',
    width: 'auto',
  },
  checkButton: {
    backgroundColor: 'orange',
    width: '2px',
    height: '2px',
  },
}));

function TeamListContent() {
  const { data, ids, loaded, onToggleItem, selectedIds } =
    useListContext<Team>();
  // const now = Date.now();
  const classes = useStyles();
  if (loaded === false) {
    return <SimpleListLoading />;
  }

  return (
    <List className={classes.raList}>
      {ids.map((id) => {
        const team = data[id];
        return (
          <ListItem
            button
            key={id}
            component={Link}
            to={`/teams/${id}/show`}
            className={classes.listItem}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={selectedIds.includes(id)}
                tabIndex={-1}
                className={classes.checkButton}
                disableRipple
                onClick={(e) => {
                  e.stopPropagation();
                  onToggleItem(id);
                }}
              />
            </ListItemIcon>

            <ListItemText
              primary={`${team.title}`}
              secondary={
                <>
                  <ReferenceField
                    record={team}
                    source="company_id"
                    reference="companies"
                    basePath="/companies"
                    link={false}
                  >
                    <TextField source="name" />
                  </ReferenceField>{' '}
                </>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
}

const useActionStyles = makeStyles((theme) => ({
  createButton: {
    marginLeft: theme.spacing(2),
  },
}));
function TeamListActions() {
  const classes = useActionStyles();
  const translate = useTranslate();
  return (
    <TopToolbar>
      <Box display="flex" flexDirection="column" width={700} mb={5}>
        <Box>
          <Typography variant="h2" component="h2" align="center">
            {translate('resources.teams.fields.header_title')}
          </Typography>
          <Typography variant="body1" component="p" align="center">
            {translate('resources.teams.fields.header_body')}
          </Typography>
        </Box>
        <Box mt={8}>
          <SortButton fields={['title']} />
          <ExportButton />
          <CreateButton
            basePath="/teams"
            variant="contained"
            label="resources.teams.fields.new_team"
            className={classes.createButton}
          />
        </Box>
      </Box>
    </TopToolbar>
  );
}

export function TeamList(props: ListProps) {
  return (
    <Box display="grid" justifyContent="center">
      <RaList
        {...props}
        actions={<TeamListActions />}
        // aside={<TeamListFilter />}
        perPage={25}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        // filterDefaultValues={{ sales_id: identity?.id }}
        sort={{ field: 'title', order: 'DESC' }}
      >
        <TeamListContent />
      </RaList>
    </Box>
  );
}
