import React from 'react';
import { Resource, ResourceProps } from 'ra-core';

import { hasAccess } from './helpers';
import { Permission } from '../../types';

interface Props extends ResourceProps {
  permissions: Record<string, Permission>;
}

export function ResourceWithPermissions({
  permissions,
  name,
  list,
  create,
  edit,
  show,
  ...props
}: Props) {
  const access = {
    enabled: hasAccess(permissions, `${name}.enabled`),
    list: hasAccess(permissions, `${name}.list`),
    create: hasAccess(permissions, `${name}.create`),
    edit: hasAccess(permissions, `${name}.edit`),
    show: hasAccess(permissions, `${name}.show`),
  };

  if (!access.enabled) {
    return null;
  }

  return (
    <Resource
      {...props}
      name={name}
      list={access.list ? list : undefined}
      create={access.create ? create : undefined}
      edit={access.edit ? edit : undefined}
      show={access.show ? show : undefined}
    />
  );
}
