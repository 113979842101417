import * as React from 'react';
import {
  Create,
  CreateProps,
  ReferenceInput,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  useTranslate,
  useGetIdentity,
} from 'react-admin';
import { Box, CardContent, Divider, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import clsx from 'clsx';

import { sectors } from './sectors';
import { sizes } from './sizes';

const useStyles = makeStyles({
  inline: {
    display: 'inline-block',
    marginRight: '1em',
    // '&.first-child': {
    //   marginLeft: 0,
    // },
  },
  textTransform: {
    textTransform: 'capitalize',
  },
});

export function CompanyCreate(props: CreateProps) {
  const { identity } = useGetIdentity();

  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Create {...props} actions={false}>
      <SimpleForm component={CustomLayout} redirect="show">
        <TextInput
          source={translate('resources.companies.fields.name')}
          validate={required()}
          fullWidth
          formClassName={classes.textTransform}
        />
        <SelectInput
          source={translate('resources.companies.fields.sector')}
          choices={sectors}
          formClassName={`${classes.inline} ${classes.textTransform}`}
        />
        <SelectInput
          source={translate('resources.companies.fields.size')}
          choices={sizes}
          formClassName={`${classes.inline} ${classes.textTransform}`}
        />
        <CustomDivider />
        <TextInput
          source={translate('resources.companies.fields.address')}
          fullWidth
          helperText={false}
          formClassName={classes.textTransform}
        />
        <TextInput
          source={translate('resources.companies.fields.city')}
          formClassName={`${classes.inline} ${classes.textTransform}`}
        />
        <TextInput
          source={translate('resources.companies.fields.zipcode')}
          formClassName={`${classes.inline} ${classes.textTransform}`}
        />
        <TextInput
          source={translate('resources.companies.fields.state_abbr')}
          formClassName={`${classes.inline} ${classes.textTransform}`}
        />
        <CustomDivider />
        <TextInput
          source={translate('resources.companies.fields.website')}
          fullWidth
          helperText={false}
          formClassName={classes.textTransform}
        />
        {/* <TextInput source="linkedIn" fullWidth helperText={false} /> */}
        <TextInput
          source={translate('resources.companies.fields.logo')}
          formClassName={classes.textTransform}
          fullWidth
        />
        <CustomDivider />
        <TextInput
          source={translate('resources.companies.fields.phone_number')}
          formClassName={`${classes.inline} ${classes.textTransform}`}
          helperText={false}
        />
        <ReferenceInput
          source="companies_id"
          reference="companies"
          label={translate('resources.companies.fields.account_manager')}
          formClassName={`${classes.inline} ${classes.textTransform}`}
          helperText={false}>
          <SelectInput
            optionText={(sales: any) =>
              translate(`${sales.first_name} ${sales.last_name}`)
            }
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}

function CustomLayout(props: any) {
  return (
    <CardContent>
      <Box display="flex" sx={{ justifyContent: 'center' }}>
        <Box paddingTop={1}>
          <Avatar>
            <BusinessIcon />
          </Avatar>
        </Box>
        <Box ml={2} flex="1" maxWidth={796}>
          {props.children}
        </Box>
      </Box>
    </CardContent>
  );
}

function CustomDivider() {
  return (
    <Box mb={2}>
      <Divider />
    </Box>
  );
}
