import * as React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  EditBase,
  EditProps,
  FormWithRedirect,
  ReferenceInput,
  TextInput,
  Toolbar,
  useEditContext,
} from 'react-admin';
import { Box, Card, CardContent, Divider } from '@material-ui/core';

import { Avatar } from './Avatar';
import { User } from '../types';
import {formOmittedPropsType} from '../form-utils';

function Spacer() {
  return <Box width={20} component="span" />;
}

function UserEditContent() {
  const { record, loaded, save } = useEditContext<User>();
  if (!loaded || !record) return null;
  const formRender = <T,>(formProps: Omit<T, formOmittedPropsType>) => (
    <Card>
      <CardContent>
        <Box>
          <Box display="flex">
            <Box mr={2}>
              <Avatar record={record} />
            </Box>
            <Box flex="1" mt={-1}>
              <Box display="flex">
                <TextInput source="first_name" />
                <Spacer />
                <TextInput source="last_name" />
              </Box>
              <Box display="flex">
                <TextInput source="title" />
                <Spacer />
                <ReferenceInput
                  source="company_id"
                  reference="companies">
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              </Box>
              <Divider />
              <Box mt={2} width={430}>
                <TextInput source="email" fullWidth />
              </Box>
              <Box display="flex">
                <TextInput source="phone_number1" />
                <Spacer />
                <TextInput source="phone_number2" />
              </Box>
              <Divider />
              <Box mt={2} width={430}>
                <TextInput source="background" multiline fullWidth />
                <TextInput source="avatar" fullWidth />
                <BooleanInput source="has_newsletter" />
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
      <Toolbar {...formProps} />
    </Card>
  );
  return (
    <Box mt={2} display="flex">
      <Box flex="1">
        <FormWithRedirect
          record={record}
          redirect="show"
          save={save}
          render={formRender}
        />
      </Box>
    </Box>
  );
}

export function UserEdit(props: EditProps) {
  return (
    <EditBase {...props}>
      <UserEditContent />
    </EditBase>
  );
}
