import GroupIcon from '@material-ui/icons/Group';
import { TeamCreate } from './TeamCreate';
import { TeamEdit } from './TeamEdit';
import { TeamList } from './TeamList';
import { TeamShow } from './TeamShow';

export default {
  list: TeamList,
  create: TeamCreate,
  edit: TeamEdit,
  show: TeamShow,
  icon: GroupIcon,
};
